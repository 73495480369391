import { FC, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
	className?: string;
	isMainTable?: boolean;
}

const TableComponent: FC<IProps> = (props) => {
	return (
		<div
			className={`${props.className ?? ''} ${
				props.isMainTable
					? 'h-[calc(100vh-260px)] 2xl:h-[calc(100vh-280px)] min-h-[340px] overflow-auto custom-scrollbar'
					: ''
			} border dark:border-gray-700`}
		>
			<table className='relative text-sm text-center rtl:text-center text-gray-500 dark:text-gray-400 w-full border-separate border-spacing-0'>
				{/* <div
					className={`${
						// make height 350px when filter comes
						props.isMainTable ? 'h-[calc(100vh-250px)] min-h-[340px] overflow-auto custom-scrollbar' : ''
					}`}
				> */}
				{props.children}
				{/* </div> */}
			</table>
		</div>
	);
};

export default TableComponent;
