import get from 'lodash/get';
import React, { useCallback, useMemo } from 'react';
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { Input } from 'shared/form/input';
import Select from 'shared/form/select/select';

import SearchableSelect from 'shared/form/select/searchableSelect';
import { IDropdownOptions } from 'shared/interface';
import ProfileEditor from 'features/addPlayer/component/form/profileEditor';
import Tournament from 'features/addPlayer/component/form/tournament';
import { IQuickAddValues } from 'features/athleteDatabase/interface';
import {
	BRAND_OPTIONS,
	CIRCUIT_LEVEL_OPTIONS,
	DISCIPLINE_OPTIONS,
	GENDER_OPTIONS,
	PLAYING_HAND,
	STATUS_OPTIONS,
	TENNIS_BRAND_OPTIONS,
	TENNIS_CIRCUIT_LEVEL_OPTIONS,
	TENNIS_DISCIPLINE_OPTIONS
} from 'features/athleteDatabase/constant';
import DateInput from 'shared/form/datepicker';

interface IProps {
	control: Control<IQuickAddValues>;
	register: UseFormRegister<IQuickAddValues>;
	setValue: UseFormSetValue<IQuickAddValues>;
	getValues: UseFormGetValues<any>;
	errors: FieldErrors<IQuickAddValues>;
	countryOptions: IDropdownOptions[];
	selectedSport?: string | undefined;
	isTennis: boolean;
}

const QuickAddAthleteInfo: React.FC<IProps> = ({
	register,
	control,
	setValue,
	errors,
	countryOptions,
	getValues,
	selectedSport,
	isTennis
}) => {
	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);

	const OPTIONS = useMemo(() => {
		return isTennis ? TENNIS_CIRCUIT_LEVEL_OPTIONS : CIRCUIT_LEVEL_OPTIONS;
	}, [isTennis]);

	const CATEGORY_OPTIONS = useMemo(() => {
		return isTennis ? TENNIS_DISCIPLINE_OPTIONS : DISCIPLINE_OPTIONS;
	}, [isTennis]);

	return (
		<>
			<ProfileEditor
				control={control}
				errors={errors}
				setValue={setValue}
				getValues={getValues}
				selectedSport={selectedSport}
			/>

			<Tournament
				control={control}
				errors={errors}
				setValue={setValue}
				getValues={getValues}
				isTennis={isTennis}
			/>
			<div className='grid grid-cols-3 gap-x-4 gap-y-2 mb-5'>
				<Input
					register={register}
					title={'First Name'}
					name='player_information.first_name'
					errorMsg={getErrorMsg('player_information.first_name')}
					placeholder='Athlete Name'
					required
				/>
				<Input
					register={register}
					title={'Last Name'}
					name='player_information.last_name'
					errorMsg={getErrorMsg('player_information.last_name')}
					placeholder='Athlete Last Name'
					required
				/>
				{isTennis && (
					<DateInput
						name='player_information.date_of_birth'
						setValue={setValue}
						control={control}
						title='Date of Birth'
						placeHolder={'Select Date'}
						required
						errorMsg={getErrorMsg('player_information.date_of_birth')}
					/>
				)}

				<Select
					placeHolder='Select Athlete Status'
					control={control}
					name='player_information.status'
					title='Status'
					options={STATUS_OPTIONS}
					errorMsg={getErrorMsg('player_information.status')}
					required
					isGroup={true}
				/>
				<Select
					placeHolder='Select Level'
					control={control}
					name='player_information.circuit_level'
					title='Circuit Level'
					options={OPTIONS}
					errorMsg={getErrorMsg('player_information.circuit_level')}
					required
				/>
				<Select
					placeHolder='Athlete Gender'
					control={control}
					name='player_information.gender'
					title='Gender'
					options={GENDER_OPTIONS}
					errorMsg={getErrorMsg('player_information.gender')}
					required
				/>

				<SearchableSelect
					control={control}
					title='Country'
					name='player_information.country'
					placeHolder='Athlete Country'
					options={countryOptions ?? []}
					required
					isStaticOptions
					errorMsg={getErrorMsg('player_information.country')}
				/>
				{!isTennis && (
					<Select
						placeHolder='Type Racquet Brand Name'
						control={control}
						name='player_information.racquet_brand'
						title='Racquet Brand'
						options={BRAND_OPTIONS}
						errorMsg={getErrorMsg('player_information.racquet_brand')}
						required
					/>
				)}

				{isTennis && (
					<Select
						placeHolder='Type Brand Name'
						control={control}
						name='player_information.brand'
						title='Brand'
						options={TENNIS_BRAND_OPTIONS}
						errorMsg={getErrorMsg('player_information.brand')}
						required
					/>
				)}
				<Select
					placeHolder='Type Category 1'
					control={control}
					name='player_information.category1'
					title='Category 1'
					options={CATEGORY_OPTIONS}
					errorMsg={getErrorMsg('player_information.category1')}
					required
				/>

				{isTennis && (
					<Select
						placeHolder='Choose Athlete Hand'
						control={control}
						name='player_information.playing_hand'
						title='Playing Hand'
						options={PLAYING_HAND}
						errorMsg={getErrorMsg('player_information.playing_hand')}
						required
					/>
				)}
			</div>
		</>
	);
};

export default QuickAddAthleteInfo;
