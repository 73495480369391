import React from 'react';
import {
	Control,
	FieldErrors,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch
} from 'react-hook-form';

import InformationModal from 'shared/components/modal/informationModal';
import useModal from 'shared/hooks/useModal';

import QuickAddAthleteInfo from './athleteInfo';
import QuickAddLevelTable from './levelTable';

import { PerformanceGradingModal } from '../../constant';
import { useAthleteContext } from 'store/athleteStore';
import { IQuickAddValues } from 'features/athleteDatabase/interface';

import QuickAddPerformanceProfile from './performanceProfile';
import Loader from 'shared/components/spinner/spinner';

interface IProps {
	control: Control<IQuickAddValues, any>;
	errors: FieldErrors<IQuickAddValues>;
	setValue: UseFormSetValue<IQuickAddValues>;
	getValues: UseFormGetValues<IQuickAddValues>;
	register: UseFormRegister<IQuickAddValues>;
	trigger: UseFormTrigger<IQuickAddValues>;
	watch: UseFormWatch<IQuickAddValues>;
	isTennis: boolean;
}

const AddQuickForm: React.FC<IProps> = (props) => {
	const { control, errors, setValue, getValues, register, trigger, watch, isTennis } = props;
	const { countryOptions, countryLoading } = useAthleteContext();

	const { isOpen, type, closeModal, openModal } = useModal<PerformanceGradingModal>();

	return (
		<>
			{countryLoading && <Loader />}
			{!countryLoading && (
				<div className='mt-4'>
					<form>
						<QuickAddAthleteInfo
							getValues={getValues}
							register={register}
							control={control}
							setValue={setValue}
							errors={errors}
							countryOptions={countryOptions}
							isTennis={isTennis}
						/>

						<div className='flex items-center justify-between'>
							<p className='title-text'>Performance Profile</p>
						</div>
						<QuickAddPerformanceProfile
							trigger={trigger}
							control={control}
							register={register}
							errors={errors}
							setValue={setValue}
							watch={watch}
							getValues={getValues}
							isTennis={isTennis}
						/>
					</form>

					{!isTennis && (
						<div className='bg-lightBlue p-4 mt-2'>
							<p className='text-sm text-textBlack font-semibold'>
								Rating process & grading guidance tip
							</p>
							<p className='text-textGray text-sm font-semibold mt-4'>
								You can check the guidelines prepared for Athlete ratings at any time{' '}
								<span
									onClick={() => openModal(PerformanceGradingModal.Performance_Grading_Guide)}
									className='text-primary cursor-pointer border-b-2 border-primary'
								>
									here
								</span>
							</p>
						</div>
					)}

					<div className='mt-5 border-b-2 border-themeGray-default' />
				</div>
			)}

			{isOpen && type === PerformanceGradingModal.Performance_Grading_Guide && (
				<InformationModal
					size={'5xl'}
					headerTile={'Performance Grading Guide'}
					show={isOpen}
					onClose={closeModal}
				>
					<div className='bg-lightBlue p-8 mt-5'>
						<div className='flex justify-around'>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>Level Bandings</p>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>
								Grading Guidance
							</p>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>Note</p>
						</div>

						<div>
							<QuickAddLevelTable />
						</div>
					</div>
				</InformationModal>
			)}
		</>
	);
};

export default AddQuickForm;
