import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import useModal from 'shared/hooks/useModal';
import ConfirmModal from 'shared/components/confirmModal';
import { FilterModal } from 'features/athleteDatabase/container';

import { defaultValues, IQuickAddValues } from 'features/athleteDatabase/interface';
import { schema, tennisSchema } from 'features/athleteDatabase/constant';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { cleanObject } from 'shared/util/utility';
import ButtonPrimary from 'shared/components/buttons/primary';
import { StarIcon } from 'shared/icon';

import AddQuickForm from './quickAddForm';
import ButtonSecondary from 'shared/components/buttons/secondary';
import { AuthContext } from 'store/userDetailStore';
import SportsFilter from 'shared/components/filter/sportsFilter';

interface IProps {
	isOpenModal: boolean;
	handleClose: () => void;
	handleClickClose: () => void;
}

const QuickAdd: React.FC<IProps> = ({ isOpenModal, handleClose, handleClickClose }) => {
	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();
	const [loading, setLoading] = useState(false);
	const [addLoading, setAddLoading] = useState(false);

	const { isTennis } = useContext(AuthContext);

	const schemaType = isTennis ? tennisSchema : schema;

	const {
		register,
		control,
		setValue,
		handleSubmit,
		getValues,
		watch,
		trigger,
		reset,
		formState: { errors, isDirty }
	} = useForm<IQuickAddValues>({
		resolver: yupResolver<any>(schemaType),
		defaultValues: defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const onSubmit: SubmitHandler<IQuickAddValues> = async (data) => {
		setLoading(true);
		const { xfactor, ...newParams } = data;
		let updatedPayload: any = { ...newParams.performance_profile };

		if (isTennis) {
			updatedPayload = {
				x_factor: newParams.performance_profile.x_factor
			};
		} else {
			// If performance_toggle is true, include only senior level fields
			if (newParams.performance_profile.performance_toggle) {
				updatedPayload = {
					team_yonex_senior_level: newParams.performance_profile.team_yonex_senior_level,
					legends_vision: newParams.performance_profile.legends_vision,
					is_junior: !updatedPayload.performance_toggle
				};
			}
			// If performance_toggle is false, include junior future star level fields
			else {
				updatedPayload = {
					team_yonex_current_performance_level:
						newParams.performance_profile.team_yonex_current_performance_level,
					training_age: newParams.performance_profile.training_age,
					potential_grade: newParams.performance_profile.potential_grade,
					is_junior: !updatedPayload.performance_toggle
				};
			}
		}

		const { country, ...updatedPlayerInformation } = newParams.player_information;

		let transformedData = {};
		if (isTennis) {
			transformedData = {
				...data,
				player_information: {
					...updatedPlayerInformation,
					country_id: +newParams.player_information.country
				},
				performance_profile: updatedPayload
			};
		} else {
			transformedData = {
				...data,
				performance_profile: updatedPayload,
				player_information: {
					...updatedPlayerInformation,
					country_id: +newParams.player_information.country
				}
			};
		}

		transformedData = cleanObject(transformedData);

		try {
			const res = await HttpService.post(
				`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}`,
				transformedData
			);
			if (res.data) {
				setLoading(false);
				reset(defaultValues);
				handleClose();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleSaveAndAdd: SubmitHandler<IQuickAddValues> = async (data) => {
		setAddLoading(true);
		const { xfactor, ...newParams } = data;

		let updatedPayload: any = { ...newParams.performance_profile };

		if (isTennis) {
			updatedPayload = {
				x_factor: newParams.performance_profile.x_factor
			};
		} else {
			if (newParams.performance_profile.performance_toggle) {
				updatedPayload = {
					team_yonex_senior_level: newParams.performance_profile.team_yonex_senior_level,
					legends_vision: newParams.performance_profile.legends_vision,
					is_junior: !updatedPayload.performance_toggle
				};
			} else {
				updatedPayload = {
					team_yonex_future_star_level: newParams.performance_profile.team_yonex_current_performance_level,
					training_age: newParams.performance_profile.training_age,
					potential_grade: newParams.performance_profile.potential_grade,
					is_junior: !updatedPayload.performance_toggle
				};
			}
		}

		const { country, ...updatedPlayerInformation } = newParams.player_information;
		let transformedData = {};
		if (isTennis) {
			transformedData = {
				...newParams,
				player_information: {
					...updatedPlayerInformation,
					country_id: +newParams.player_information.country
				},
				performance_profile: updatedPayload
			};
		} else {
			transformedData = {
				...newParams,
				performance_profile: updatedPayload,
				player_information: {
					...updatedPlayerInformation,
					country_id: +newParams.player_information.country
				}
			};
		}

		transformedData = cleanObject(transformedData);

		try {
			const res = await HttpService.post(
				`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}`,
				transformedData
			);
			if (res.data) {
				setAddLoading(false);
				reset(defaultValues);
			}
		} catch (error) {
			setAddLoading(false);
			console.log(error);
		}
	};

	const handleCloseWithCheck = () => {
		if (isDirty) {
			openModal(FilterModal.CONFIRM_POPUP); // Show confirmation modal if form is dirty
			handleClose();
		} else {
			handleClickClose(); // Directly close if form is not dirty
		}
	};

	return (
		<div>
			<Modal show={isOpenModal} onClose={handleCloseWithCheck} theme={Theme} size='4xl'>
				<ModalHeader>
					<div className='flex items-center justify-between'>
						<p className='text-lg font-semibold text-textBlack'>Quick Add Athlete</p>
						<div className='flex justify-end gap-4 mr-2'>
							<div className='flex'>
								<ButtonPrimary
									btnTxt='Save & Add New Athlete'
									onClick={handleSubmit(handleSaveAndAdd)}
									isLoading={addLoading}
								/>
								<ButtonSecondary
									btnTxt={'Save & Go To Athlete Database'}
									className='ml-3'
									onClick={handleSubmit(onSubmit)}
									isLoading={loading}
								/>
							</div>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className='bg-lightBlue p-4'>
						<div className='mb-4 w-8/12'>
							<SportsFilter reset={reset} />
						</div>

						<div className='flex items-center mb-6 mt-1'>
							<StarIcon className='mr-3' />
							<p className='text-sm font-normal text-textGray'>- means mandatory fields</p>
						</div>

						<AddQuickForm
							control={control}
							register={register}
							getValues={getValues}
							setValue={setValue}
							watch={watch}
							trigger={trigger}
							errors={errors}
							isTennis={isTennis}
						/>
					</div>
				</ModalBody>
			</Modal>

			{isOpen && type === FilterModal.CONFIRM_POPUP && (
				<ConfirmModal
					btnPrimaryTxt='Yes'
					btnSecondaryTxt='No'
					show={isOpen}
					onSubmit={handleClickClose}
					onClose={() => {
						closeModal();
					}}
					primaryText={'Unsaved changes !'}
					secondaryText={'You have unsaved changes. Are you sure you want to discard them ?'}
				/>
			)}
		</div>
	);
};

export default QuickAdd;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-4'
	},
	header: {
		base: 'flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600',
		title: 'w-full'
	},
	footer: {
		base: 'border-none'
	},
	content: {
		base: 'relative h-full w-full p-4 md:h-auto',
		inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 overflow-y-scroll'
	}
};

export const TABS = [
	{
		title: 'Badminton',
		key: 'badminton'
	},
	{
		title: 'Tennis',
		key: 'tennis'
	}
];

export enum TAB_KEY {
	BADMINTON = 'Badminton',
	TENNIS = 'Tennis'
}
