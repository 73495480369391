import React, { PropsWithChildren, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Modal } from 'flowbite-react';

import ButtonPrimary from '../buttons/primary';
import ButtonSecondary from '../buttons/secondary';
import { NotesIcon } from 'shared/icon';
import { Textarea } from 'shared/form/textarea';
import { TOURNAMENT_OPTIONS } from 'shared/constants';
import Select from 'shared/form/select/select';
import { NoteModal } from 'features/playerProfile/constant';
import ConfirmModal from 'shared/components/confirmModal';
import useModal from 'shared/hooks/useModal';

interface IProps extends PropsWithChildren {
	className?: string;
	headerTile: string;
	subHeaderTitle?: string;
	show: boolean;
	disable?: boolean;
	onClose: () => void;
	formSubmit?: (data: any) => void;
	isQuickNote: boolean;
	initialData?: any;
	type?: string;
}

type FormValues = {
	note: string;
	tournament?: string;
};

const enum ConfirmPopupModal {
	CONFIRM_POPUP = 'Confirm Popup'
}

const AddNoteModal: React.FC<IProps> = ({
	show,
	className,
	disable = false,
	onClose,
	formSubmit,
	headerTile,
	isQuickNote,
	initialData,
	type,
	subHeaderTitle
}) => {
	const {
		register,
		handleSubmit,
		control,
		reset,
		getValues,
		watch,
		formState: { isDirty }
	} = useForm<FormValues>({
		defaultValues: initialData as FormValues
	});
	const confirmModal = useModal<ConfirmPopupModal>();

	const noteValue = watch('note');
	const tournamentValue = watch('tournament');

	useEffect(() => {
		if (show && initialData) {
			reset(initialData);
		}
	}, [show, initialData, reset]);

	const isDisabled = disable || (!noteValue && !isQuickNote && !tournamentValue) || !getValues('note').trim();

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		formSubmit && formSubmit(data);
		onClose();
	};

	const handleConfirmYes = () => {
		onClose(); // Closes the main modal
		confirmModal.closeModal(); // Closes the confirm modal
	};

	const handleConfirmNo = () => {
		confirmModal.closeModal();
	};

	const onCloseButtonClick = () => {
		if (isDirty) {
			confirmModal.openModal(ConfirmPopupModal.CONFIRM_POPUP);
		} else {
			onClose();
		}
	};

	return (
		<>
			<Modal className={`${className || ''}`} show={show} onClose={onCloseButtonClick} theme={Theme}>
				<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
					<div>
						<div className='border-borderGray border shadow-sm rounded-lg size-12 flex items-center justify-center mb-5'>
							<NotesIcon className='stroke-primary' />
						</div>
						<p className='header-txt'>{headerTile}</p>
						<p className='sub-header-txt '>
							{subHeaderTitle || 'Leave a comment for specific Athlete observations'}
						</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<form>
						{!isQuickNote && (
							<Select
								menuPosition='bottom'
								placeHolder='Type Tournament Name'
								className='my-2'
								control={control}
								name='tournament'
								title='Tournament'
								options={TOURNAMENT_OPTIONS}
								errorMsg=''
							/>
						)}
						<Textarea
							register={register}
							title={'Note'}
							name='note'
							placeholder='Add Note Here'
							errorMsg=''
							cols={30}
							rows={9}
							//validate={(value: string) => value.trim() !== '' || 'Empty spaces are not allowed.'}
						/>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<ButtonSecondary className='w-full' onClick={onCloseButtonClick} btnTxt='Cancel' />
					<ButtonPrimary
						className='w-full'
						onClick={handleSubmit(onSubmit)}
						btnTxt={type === NoteModal.addNote ? 'Add Note' : 'Edit Note'}
						disable={isDisabled}
					/>
				</Modal.Footer>
			</Modal>
			{confirmModal.isOpen && confirmModal.type === ConfirmPopupModal.CONFIRM_POPUP && (
				<ConfirmModal
					btnPrimaryTxt='Yes'
					btnSecondaryTxt='No'
					show={confirmModal.isOpen}
					onSubmit={handleConfirmYes} // Closes both modals
					onClose={handleConfirmNo} // Closes only the confirm modal
					primaryText={'Unsaved changes !'}
					secondaryText={'You have unsaved changes. Are you sure you want to discard them ?'}
				/>
			)}
		</>
	);
};

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};

export default AddNoteModal;
