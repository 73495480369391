import * as Yup from 'yup';

export const schema = Yup.object({
	player_information: Yup.object({
		first_name: Yup.string().trim().required('First Name is required'),
		last_name: Yup.string().trim().required('Last Name is required'),
		racquet_brand: Yup.string().required('Racquet Brand is required'),
		gender: Yup.string().required('Gender is required'),
		status: Yup.string().required('Status is required'),
		circuit_level: Yup.string().required('Circuit Level is required'),
		category1: Yup.string().required('Category 1 is required'),
		country: Yup.string().required('Country is required'),
		tournament: Yup.string().required('Tournament is required')
	}),
	performance_profile: Yup.object({
		team_yonex_current_performance_level: Yup.string().when('performance_toggle', {
			is: false, // When toggle is false, the field is required
			then: (schema) => schema.required('Current Performance Level is required'),
			otherwise: (schema) => schema.notRequired()
		}),
		potential_grade: Yup.string().when('performance_toggle', {
			is: false, // When toggle is false, the field is required
			then: (schema) => schema.required('Potential Grade is required'),
			otherwise: (schema) => schema.notRequired()
		})
	})
});

export const tennisSchema = Yup.object({
	player_information: Yup.object({
		first_name: Yup.string().trim().required('First name is required'),
		last_name: Yup.string().trim().required('Last name is required'),
		gender: Yup.string().required('Gender is required'),
		country: Yup.string().required('Country is required'),
		brand: Yup.string().required('Brand is required'),
		status: Yup.string().required('Status is required'),
		date_of_birth: Yup.string().required('Date of Birth is required'),
		playing_hand: Yup.string().required('Playing hand is required'),
		circuit_level: Yup.string().required('Circuit Level is required'),
		category1: Yup.string().required('Category 1 is required'),
		tournament: Yup.string().required('Tournament is required')
	}),
	performance_profile: Yup.object({
		x_factor: Yup.string().trim().required('X factor is required')
	})
});

export const enum PerformanceGradingModal {
	Performance_Grading_Guide = 'Performance Grading Guide'
}

export const enum Status {
	Identification = 'Identification',
	Reviewed = 'Reviewed',
	Not_of_Interest = 'Not of Interest',
	Monitoring = 'Monitoring',
	Priority_Look_to_Secure = 'Priority - Look to Secure',
	Priority_with_Competitor = 'Priority - with Competitor',
	Competitor_Sponsored = 'Competitor Sponsored',
	Secured_Individual = 'Secured Individual',
	Secured_Team_Association = 'Secured Team or Association',
	Retired = 'Retired'
}

export const STATUS_OPTIONS = [
	{ label: Status.Identification, value: Status.Identification, isAthlete: false },
	{ label: Status.Reviewed, value: Status.Reviewed, isAthlete: false },
	{ label: Status.Monitoring, value: Status.Monitoring, isAthlete: false },
	{ label: Status.Not_of_Interest, value: Status.Not_of_Interest, isAthlete: false },
	{ label: Status.Priority_Look_to_Secure, value: Status.Priority_Look_to_Secure, isAthlete: false },
	{ label: Status.Priority_with_Competitor, value: Status.Priority_with_Competitor, isAthlete: false },
	{ label: Status.Competitor_Sponsored, value: Status.Competitor_Sponsored, isAthlete: false },
	{ label: Status.Secured_Individual, value: Status.Secured_Individual, isAthlete: true },
	{ label: Status.Secured_Team_Association, value: Status.Secured_Team_Association, isAthlete: true },
	{ label: Status.Retired, value: Status.Retired, isAthlete: true }
];

export const CIRCUIT_LEVEL_OPTIONS = [
	{ label: 'U11', value: 'U11' },
	{ label: 'U13', value: 'U13' },
	{ label: 'U15', value: 'U15' },
	{ label: 'U17', value: 'U17' },
	{ label: 'U19', value: 'U19' },
	{ label: 'Professional', value: 'Professional' },
	{ label: 'Junior', value: 'Junior' }
];

export const TENNIS_CIRCUIT_LEVEL_OPTIONS = [
	{ label: 'U10', value: 'U10' },
	{ label: 'U12', value: 'U12' },
	{ label: 'U14', value: 'U14' },
	{ label: 'U16', value: 'U16' },
	{ label: 'U18', value: 'U18' },
	{ label: 'College', value: 'College' },
	{ label: 'Professional', value: 'Professional' }
];

export const GENDER_OPTIONS = [
	{ label: 'Female', value: 'Female' },
	{ label: 'Male', value: 'Male' }
];

export const TENNIS_BRAND_OPTIONS = [
	{ label: 'Babolat', value: 'Babolat' },
	{ label: 'Dunlop', value: 'Dunlop' },
	{ label: 'Head', value: 'Head' },
	{ label: 'Tecnifibre', value: 'Tecnifibre' },
	{ label: 'Wilson', value: 'Wilson' },
	{ label: 'Yonex', value: 'Yonex' },
	{ label: 'Artengo', value: 'Artengo' },
	{ label: 'Other', value: 'Other' }
];

export const BRAND_OPTIONS = [
	{ label: 'Yonex', value: 'Yonex' },
	{ label: 'Li-Ning', value: 'Li-Ning' },
	{ label: 'Victor', value: 'Victor' },
	{ label: 'Babolat', value: 'Babolat' },
	{ label: 'APACS', value: 'APACS' },
	{ label: 'FZ Forza', value: 'FZ Forza' },
	{ label: 'Carlton', value: 'Carlton' },
	{ label: 'Talbot', value: 'Talbot' },
	{ label: 'Ashaway', value: 'Ashaway' },
	{ label: 'Felet', value: 'Felet' },
	{ label: 'Karakal', value: 'Karakal' },
	{ label: 'Protech', value: 'Protech' },
	{ label: 'None', value: 'None' }
];

export const DISCIPLINE_OPTIONS = [
	{ label: 'MS', value: 'MS' },
	{ label: 'WS', value: 'WS' },
	{ label: 'MD', value: 'MD' },
	{ label: 'WD', value: 'WD' },
	{ label: 'XD', value: 'XD' }
];

export const TENNIS_DISCIPLINE_OPTIONS = [
	{ label: 'Singles', value: 'Singles' },
	{ label: 'Doubles', value: 'Doubles' },
	{ label: 'Mixed', value: 'Mixed' }
];

export const RECRUIT_CHANCE = [
	{ label: 'Recruited', value: 'Recruited' },
	{ label: 'Good Chance', value: 'Good Chance' },
	{ label: 'Maybe', value: 'Maybe' },
	{ label: 'WD', value: 'WD' },
	{ label: 'No Chance', value: 'No Chance' }
];

export const STRING_MODAL = [
	{ label: 'Polytour Air', value: 'Polytour Air' },
	{ label: 'Polytour Drive', value: 'Polytour Drive' },
	{ label: 'Polytour Tough', value: 'Polytour Tough' },
	{ label: 'Polytour Rev', value: 'Polytour Rev' },
	{ label: 'Polytour Spin', value: 'Polytour Spin' },
	{ label: 'Polytour Strike', value: 'Polytour Strike' },
	{ label: 'Polytour Pro', value: 'Polytour Pro' },
	{ label: 'Polytour Fire', value: 'Polytour Fire' },
	{ label: 'Dynawire', value: 'Dynawire' },
	{ label: 'Rexis Feel', value: 'Rexis Feel' },
	{ label: 'Rexis Speed', value: 'Rexis Speed' },
	{ label: 'Rexis Confrot', value: 'Rexis Confrot' },
	{ label: 'Other', value: 'Other' }
];

export const CLOTHING_SHOE_BRAND = [
	{ label: 'Adidas', value: 'Adidas' },
	{ label: 'Asics', value: 'Asics' },
	{ label: 'Babolat', value: 'Babolat' },
	{ label: 'Diadora', value: 'Diadora' },
	{ label: 'Dunlop', value: 'Dunlop' },
	{ label: 'Ellesse', value: 'Ellesse' },
	{ label: 'FILA', value: 'FILA' },
	{ label: 'K-Swiss Global Brands', value: 'K-Swiss Global Brands' },
	{ label: 'Lacoste', value: 'Lacoste' },
	{ label: 'Luxilonl', value: 'Luxilon' },
	{ label: 'Mizuno', value: 'Mizuno' },
	{ label: 'Nike', value: 'Nike' },
	{ label: 'ON', value: 'ON' },
	{ label: 'Prince', value: 'Prince' },
	{ label: 'Sergio Tacchini', value: 'Sergio Tacchini' },
	{ label: 'Solinco', value: 'Solinco' },
	{ label: 'Tecnifibre', value: 'Tecnifibre' },
	{ label: 'UNIQLO', value: 'UNIQLO' },
	{ label: 'Wilson', value: 'Wilson' },
	{ label: 'Yonex', value: 'Yonex' }
];

export const RACKET_MODEL = [
	{ label: 'Vcore 95', value: 'Vcore 95' },
	{ label: 'Vcore 98', value: 'Vcore 98' },
	{ label: 'Vcore 98L', value: 'Vcore 98L' },
	{ label: 'Vcore 100', value: 'Vcore 100' },
	{ label: 'Vcore 100L', value: 'Vcore 100L' },
	{ label: 'Vcore 98+', value: 'Vcore 98+' },
	{ label: 'Ezone 98', value: 'Ezone 98' },
	{ label: 'Ezone 98 Tour', value: 'Ezone 98 Tour' },
	{ label: 'Ezone 98L', value: 'Ezone 98L' },
	{ label: 'Ezone 100', value: 'Ezone 100' },
	{ label: 'Ezone 100L', value: 'Ezone 100L' },
	{ label: 'Ezone 100SL', value: 'Ezone 100SL' },
	{ label: 'Vcore Pro 97D', value: 'Vcore Pro 97D' },
	{ label: 'Vcore Pro 97H', value: 'Vcore Pro 97H' },
	{ label: 'Vcore Pro 97', value: 'Vcore Pro 97' },
	{ label: 'Vcore Pro 97L', value: 'Vcore Pro 97L' },
	{ label: 'Vcore Pro 100', value: 'Vcore Pro 100' },
	{ label: 'Vcore Pro 100L', value: 'Vcore Pro 100L' },
	{ label: 'Other', value: 'Other' }
];

export const FUTURE_LEVEL_OPTIONS = [
	{ label: 'Domestic Success', value: 'Domestic Success' },
	{ label: 'Continental Success', value: 'Continental Success' },
	{ label: 'Global Success', value: 'Global Success' }
];

export const POTENTIAL_GRADE_OPTIONS = [
	{ label: 'OC / WC', value: 'OC / WC' },
	{ label: 'Top 5', value: 'Top 5' },
	{ label: 'Top 10', value: 'Top 10' },
	{ label: 'Top 20', value: 'Top 20' },
	{ label: 'Top 30', value: 'Top 30' },
	{ label: 'Top 50', value: 'Top 50' },
	{ label: 'Top 100', value: 'Top 100' }
];
export const LEGENDS_OPTIONS = [
	{ label: 'Founding Legend', value: 'Founding Legend' },
	{ label: 'Legend And Ambassador', value: 'Legend And Ambassador' }
];

export const SENIOR_STAR_OPTIONS = [
	{ label: 'Local Star', value: 'Local Star' },
	{ label: 'Badminton Star', value: 'Badminton Star' },
	{ label: 'Rockstar', value: 'Rockstar' }
];

export const SPORTS_OPTIONS = [
	{ label: 'Badminton', value: 'Badminton' },
	{ label: 'Tennis', value: 'Tennis' }
];

export const PLAYING_HAND = [
	{ label: 'Right', value: 'Right' },
	{ label: 'Left', value: 'Left' }
];

export const selectAthleteModalTheme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1020px]'
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};

export const buttonGroupTheme = { position: { middle: 'rounded-r-none ring-0 focus:ring-offset-0' } };

export const searchBarTheme = {
	field: {
		input: {
			colors: {
				gray: 'border-themeGray focus:border-primary-light focus:ring-primary-light pr-6'
			},
			sizes: {
				md: 'text-base-responsive',
				sm: 'text-sm-responsive max-w-[120px] 2xl:max-w-[242px]'
			}
		}
	}
};

export const filterButtonTheme = {
	color: {
		light: 'border-[none]'
	}
};

export const ATHLETE_TABLE_CELL = [
	{ title: 'Athlete Name', key: 'athlete_name' },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true },
	{ title: 'Gender', key: 'gender' },
	{ title: 'Circuit Lvl.', key: 'circuit_level' },
	{ title: 'Category', key: 'category1' },
	{ title: 'OPS.', key: 'perform', shortKey: 'Performance', sortable: true }
];

export const TENNIS_ATHLETE_TABLE_CELL = [
	{ title: 'Athlete Name', key: 'athlete_name' },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true },
	{ title: 'Gender', key: 'gender' },
	{ title: 'OPS.', key: 'perform', shortKey: 'Performance', sortable: true }
];

export enum PLAYER_KPI {
	FOOTWORK = 'Footwork',
	TECHNICAL = 'Technical',
	TACTICAL = 'Tactical',
	PSYCHOLOGICAL = 'Psychological',
	PHYSICAL = 'Physical',
	DAILY_PRACTICE_OR_LIFESTYLE = 'Daily Practice Or Lifestyle',
	PERFORMANCE_ENVIRONMENT = 'Performance Environment'
}

export enum TENNIS_PLAYER_KPI {
	PHYSICAL = 'Physical',
	TECHNICAL = 'Technical',
	TACTICAL = 'Tactical',
	MENTAL = 'Mental'
}

export const KpiGroup = [
	PLAYER_KPI.FOOTWORK,
	PLAYER_KPI.TECHNICAL,
	PLAYER_KPI.TACTICAL,
	PLAYER_KPI.PSYCHOLOGICAL,
	PLAYER_KPI.PHYSICAL,
	PLAYER_KPI.DAILY_PRACTICE_OR_LIFESTYLE,
	PLAYER_KPI.PERFORMANCE_ENVIRONMENT
];

export const TennisKpiGroup = [
	TENNIS_PLAYER_KPI.PHYSICAL,
	TENNIS_PLAYER_KPI.TECHNICAL,
	TENNIS_PLAYER_KPI.TACTICAL,
	TENNIS_PLAYER_KPI.MENTAL
];

export const ATHLETES_OPTIONS = [
	{ label: 'All Athletes', value: 'All Athlete' },
	{ label: 'Yonex Athletes', value: 'Yonex Athlete' },
	{ label: 'Non-Yonex Athletes', value: 'Non-Yonex Athlete' }
];

export const NON_YONEX_ATHLETE = [
	Status.Identification,
	Status.Reviewed,
	Status.Not_of_Interest,
	Status.Monitoring,
	Status.Priority_Look_to_Secure,
	Status.Priority_with_Competitor,
	Status.Competitor_Sponsored
];

export const YONEX_ATHLETE = [Status.Secured_Individual, Status.Secured_Team_Association, Status.Retired];
