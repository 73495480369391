import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';

import TableComponent from 'shared/components/table/table';
import TableHeader from 'shared/components/table/tableHeader';
import TableBody from 'shared/components/table/tableBody';
import Pagination from 'shared/components/pagination';
import useModal from 'shared/hooks/useModal';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import TableSpinner from 'shared/components/table/tableSpinner';
import EmptyTable from 'shared/components/table/emptyTable';
import { ISortData } from 'shared/interface';
import { cleanObject } from 'shared/util/utility';
import { useAthleteContext } from 'store/athleteStore';
import { FILTERS, IFilters, initFilters, initTennisFilters, IPlayers, ViewType } from '../interface';

import PlayerList from '../component/playerList';
import PlayerMatrix from '../component/playerMatrixModal';
import PlayerCardView from '../component/playerCardView';
import { useNavigate } from 'react-router-dom';
import Filters from '../component/filter/filters';
import PlayerFilterModal from '../component/filter/filterModal';
import QuickAdd from '../component/quickAdd';
import { useAuthContext } from 'store/userDetailStore';

export const enum FilterModal {
	Filter_Modal = 'Filter Modal',
	View_Matrix = 'View Matrix',
	DELETE_ATHLETE = 'Delete Athlete',
	QUICK_ADD = 'Quick Add',
	CONFIRM_POPUP = 'Confirm Popup'
}

const AthleteDatabase = () => {
	const { athleteFilters, setAthleteFilters } = useAthleteContext();
	const { isTennis } = useAuthContext();
	const [viewType, setViewType] = useState<string>(ViewType.Table_View);
	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] = useState({ current_page: 1, record_per_page: 10, total_pages: 1 });
	const [totalRecord, setTotalRecords] = useState<number>();
	const [playerList, setPlayerList] = useState([] as IPlayers[]);
	const [savedFilterName, setSavedFilterName] = useState('');

	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();

	const navigate = useNavigate();

	const isFirstRender = useRef(true);

	const fetchPlayers = async (filters: IFilters) => {
		const params = cleanObject(filters);
		try {
			setLoading(true);
			const res = await HttpService.get(`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}`, params);
			setPlayerList(res.data.data);
			setTotalRecords(res.data.total);
			setPagination((pre) => ({
				...pre,
				total_pages: res.data.total_pages,
				current_page: res.data.current_page
			}));
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchPlayers({ ...athleteFilters });
	}, [athleteFilters]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false; // Skip the first render
			return;
		}
		// reset filters on change of sport
		setAthleteFilters((pre) => ({ ...pre, ...(isTennis ? initTennisFilters : initFilters) }));
	}, [isTennis, setAthleteFilters]);

	const onChangeFilter = (key: FILTERS, value: string) => {
		setAthleteFilters((pre) => ({ ...pre, [key]: value }));
	};

	const onSort = (sortData: ISortData) => {
		setAthleteFilters({ ...athleteFilters, ...sortData });
	};

	const handleChangeLayout = (type: string) => {
		setViewType(type);
	};

	const handleDeletePlayer = async (id: string, sport: string) => {
		const params = {
			sport: sport
		};
		try {
			await HttpService.deleteRequest(`${API_CONFIG.player}/${id}`, params);
			fetchPlayers({ ...athleteFilters });
		} catch (err) {
			console.log(err);
		}
	};

	const onPageChange = (page: number) => {
		fetchPlayers({ ...athleteFilters, current_page: page });
	};

	const areFiltersApplied = (filters: IFilters, initFilters: IFilters) => {
		return !isEqual(filters, initFilters); // Check if filters are different from the initial state
	};

	const filtersApplied = areFiltersApplied(athleteFilters, initFilters);

	const TableRows = useMemo(() => (isTennis ? Tennis_Table_Cells : Table_Cells), [isTennis]);

	return (
		<>
			<Filters
				isTennis={isTennis}
				filters={athleteFilters}
				onChangeFilter={onChangeFilter}
				viewType={viewType}
				handleChangeLayout={handleChangeLayout}
				openModal={openModal}
				totalAthlete={totalRecord}
				savedFilterName={savedFilterName}
			/>

			{viewType === ViewType.Table_View ? (
				<TableComponent isMainTable>
					<TableHeader
						tableCellList={TableRows}
						sortData={{ sort_by: athleteFilters.sort_by, order_by: athleteFilters.order_by } as ISortData}
						onSort={onSort}
						viewMatrix={() => openModal(FilterModal.View_Matrix)}
					/>
					<TableBody>
						{loading && <TableSpinner colSpan={TableRows.length} />}
						{!loading && !isEmpty(playerList) && playerList.length > 0 && (
							<PlayerList
								rows={TableRows}
								playerData={playerList}
								handleDeletePlayer={handleDeletePlayer}
							/>
						)}
						{!loading && isEmpty(playerList) && playerList.length <= 0 && (
							<EmptyTable
								btnTxt='Register New Athlete'
								onClick={() => navigate('/add-player')}
								colSpan={TableRows.length}
								text='Add Athletes and Monitor They Performance'
								hideRegisterButton={filtersApplied}
							/>
						)}
					</TableBody>
				</TableComponent>
			) : (
				<PlayerCardView playerData={playerList} loading={loading} />
			)}
			<Pagination loading={loading} onPageChange={onPageChange} pagination={pagination} />
			{isOpen && type === FilterModal.View_Matrix && <PlayerMatrix show={isOpen} onClose={closeModal} />}
			{isOpen && type === FilterModal.Filter_Modal && (
				<PlayerFilterModal
					show={isOpen}
					onClose={closeModal}
					onChangeFilter={(otherFilters) => setAthleteFilters({ ...athleteFilters, ...otherFilters })}
					filters={athleteFilters}
					setSavedFilterName={setSavedFilterName}
				/>
			)}

			{isOpen && type === FilterModal.QUICK_ADD && (
				<QuickAdd
					isOpenModal={isOpen}
					handleClickClose={closeModal}
					handleClose={() => {
						closeModal();
						fetchPlayers(athleteFilters);
					}}
				/>
			)}
		</>
	);
};

export default AthleteDatabase;

const common_cell = [
	{ title: 'Athlete name', key: 'playerName', style: { width: '250px' } },
	// { title: 'Sport', key: 'sport', shortKey: 'Sport', sortable: true, style: { width: '100px' } },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true, style: { width: '100px' } },
	{ title: 'Gender', key: 'gender', style: { width: '50px' } },
	{ title: 'Circuit Lvl.', key: 'circuitLvl', shortKey: 'Circuit Level', sortable: true, style: { width: '50px' } }
];

const common_cell_1 = [
	{ title: 'Status', key: 'status', shortKey: 'Status', sortable: true, style: { width: '165px' } },
	{
		title: 'OPS',
		key: 'perform',
		shortKey: 'Performance',
		sortable: true,
		style: { width: '80px' },
		tooltip: true,
		tooltipText: 'Overall Performance Score'
	},
	{
		title: 'TSMF',
		key: 'totalFollower',
		sortable: true,
		shortKey: 'Total Social Media Count',
		style: { width: '100px' },
		tooltip: true,
		tooltipText: 'Total Social Media Followers'
	},
	{
		title: 'Marketability C.',
		key: 'marketabilityC',
		shortKey: 'Marketability Current',
		tooltip: true,
		tooltipText: 'Marketability Current',
		sortable: true,
		style: { width: '140px' }
	},
	{
		title: 'Marketability P.',
		key: 'marketabilityP',
		shortKey: 'Marketability Potential',
		sortable: true,
		tooltipText: 'Marketability Potential',
		tooltip: true,
		style: { width: '140px' }
	}
];

const Table_Cells = [
	...common_cell,
	{ title: 'Athlete ID', key: 'worldRank', style: { width: '80px' } },
	...common_cell_1,
	{ title: 'Yonex lvl.', key: 'yonexLvl', style: { width: '120px' } },
	{ title: 'Contract Status ', key: 'expDate', style: { width: '100px' } },
	{ title: '', key: 'action', style: { width: '50px' } }
];

export const Tennis_Table_Cells = [
	...common_cell,
	...common_cell_1,
	{ title: 'Contract Status ', key: 'expDate', style: { width: '100px' } },
	{ title: '', key: 'action', style: { width: '50px' } }
];
