import React, { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import AuthService, { setUserSport } from 'shared/services/auth.service';
import { IUserData } from 'shared/interface';
import { ROLES } from 'shared/components/permissions/constants';
import { SPORT } from 'shared/constants';

export const useAuthContext = (): IContextType => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuthContext must be used within a AuthStore');
	}
	return context;
};

const AuthStore: React.FC<PropsWithChildren> = ({ children }) => {
	const [isLogin, setIsLogin] = useState<boolean>(AuthService.isLogin());
	const [userData, setUserData] = useState<IUserData>(AuthService.getAuthData() || ({} as IUserData));
	const [tempToken, setTempToken] = useState<string>(AuthService.getTempToken() || '');
	const [sport, setSport] = useState<SPORT>(AuthService.getUserSport());

	const logout = useCallback(() => {
		AuthService.removeAuthData();
		setIsLogin(false);
		setUserData({} as IUserData);
	}, []);

	const changeSports = useCallback(
		(sport: SPORT) => {
			setSport(sport);
			setUserSport(sport);
		},
		[setSport]
	);

	const login = useCallback(
		(data: IUserData) => {
			AuthService.setAuthData(data);
			if (data.sports === SPORT.Both) {
				changeSports(SPORT.Badminton);
			} else {
				changeSports(data.sports as SPORT);
			}
			setIsLogin(true);
			setUserData(data);
		},
		[setIsLogin, setUserData, changeSports]
	);

	const setAuthToken = useCallback((token: string) => {
		setTempToken(token);
		AuthService.setTempToken(token);
	}, []);

	const isTennis = useMemo(() => sport === SPORT.Tennis, [sport]);

	const contextData = useMemo(
		() => ({
			logout,
			login,
			isLogin,
			setIsLogin,
			userData,
			setUserData,
			userEmail: userData.email,
			tempToken,
			setAuthToken,
			roles: userData.user_link_modules,
			sport,
			changeSports,
			isTennis
		}),
		[isLogin, login, logout, userData, tempToken, setAuthToken, sport, changeSports, isTennis]
	);

	return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

interface IContextType {
	isLogin: boolean;
	userData: IUserData;
	userEmail: string;
	setUserData: React.Dispatch<React.SetStateAction<IUserData>>;
	setIsLogin: (isLogin: boolean) => void;
	logout: () => void;
	login: (data: IUserData) => void;
	tempToken: string;
	roles: ROLES[];
	setAuthToken: (data: string) => void;
	sport: SPORT;
	isTennis: boolean;
	changeSports: (data: SPORT) => void;
}

export const AuthContext = createContext({} as IContextType);
export default AuthStore;
