import React, {
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import { IKpiDataNew } from 'features/addPlayer/interface';
import { ICountDetails } from 'shared/components/header/interface/interface';
import { ROLES } from 'shared/components/permissions/constants';
import useAccessPermission from 'shared/components/permissions/userAccessPermissions';

import { initFilters as athleteInitFilters, ViewType } from 'features/athleteDatabase/interface';
import {
	initScoutingPlayerFilters,
	IScoutingFilters,
	IScoutingPlayerFilters,
	initShortListFilter as scoutingInitFilters
} from 'features/shortlist/interface';
import { IFilters as IAthleteFilters } from 'features/athleteDatabase/interface';
import {
	initRecruitingFilter,
	initRecruitingPlayerFilters,
	IRecruitingFilters,
	IRecruitingPlayerFilters
} from 'features/recruitingDashboard/interface';
import { initFilters as allAthleteInitFilter } from 'features/allAthlates/component/filter/filter';
import { initQuickFilters } from 'features/allAthlates/component/filter/quickFilter';
import { IAllAthleteFilters } from 'features/allAthlates/constant';
import { IContractFilters, initContractFilters } from 'features/contractManagement/interface';
import { AuthContext } from './userDetailStore';
import { SPORT } from 'shared/constants';

export const useAthleteContext = (): IContextType => {
	const context = useContext(AthleteContext);
	if (!context) {
		throw new Error('useAthleteContext must be used within a AthleteStore');
	}
	return context;
};

const AthleteStore: React.FC<PropsWithChildren> = ({ children }) => {
	const [athleteFilters, setAthleteFilters] = useState<IAthleteFilters>(athleteInitFilters);
	const [scoutingFilters, setScoutingFilters] = useState<IScoutingFilters>(scoutingInitFilters);
	const [recruitingFilters, setRecruitingFilters] = useState<IRecruitingFilters>(initRecruitingFilter);
	const [allAthleteFilters, setAllAthleteFilters] = useState<IAllAthleteFilters>({
		...allAthleteInitFilter,
		...initQuickFilters
	});
	const [scoutingPlayerFilters, setScoutingPlayerFilters] = useState<IScoutingPlayerFilters>({
		...initScoutingPlayerFilters,
		...initQuickFilters
	});

	const [recruitingPlayerFilters, setRecruitingPlayerFilters] = useState<IRecruitingPlayerFilters>({
		...initRecruitingPlayerFilters,
		...initQuickFilters
	});

	const [contractFilters, setContractFilters] = useState<IContractFilters>(initContractFilters);

	const [countryLoading, setCountryLoading] = useState(false);
	const [kpiLoading, setKpiLoading] = useState(false);
	const [countryOptions, setCountryOptions] = useState([]);
	const [kpiData, setKpiData] = useState([]);
	const [tennisKpiData, setTennisKpiData] = useState([]);

	const [countDetails, setCountDetails] = useState<ICountDetails>({} as ICountDetails);

	const [viewType, setViewType] = useState<string>(ViewType?.Table_View);

	const { sport, userData } = useContext(AuthContext);
	const havePermission = useAccessPermission([ROLES.SCOUTING_RECRUITING]);

	const fetchCountryList = useCallback(async () => {
		try {
			setCountryLoading(true);
			const res = await HttpService.get(API_CONFIG.countryList);
			const countryOptions = res.data?.map((country: any) => ({
				label: `${country.emoji} ${country.name}`,
				value: country.id,
				name: country.name,
				iso3: country.iso3,
				continent: country.continent,
				emoji: country.emoji
			}));
			setCountryOptions(countryOptions);
			setCountryLoading(false);
		} catch (error) {
			console.log(error);
			setCountryLoading(false);
		}
	}, []);

	const fetchKPIList = useCallback(async (sports: SPORT) => {
		try {
			setKpiLoading(true);
			if (sports === SPORT.Badminton || sports === SPORT.Both) {
				const res = await HttpService.get(API_CONFIG.kpiListNew);
				if (res.data && res.data.length > 0) {
					const kpiData = res.data.map((kpi: any) => ({
						...kpi
					}));
					setKpiData(kpiData);
				}
			}

			if (sports === SPORT.Tennis || sports === SPORT.Both) {
				const tennisKpis = await HttpService.get(API_CONFIG.tennisKpiList);
				if (tennisKpis.data && tennisKpis.data.length > 0) {
					const tennisKpiData = tennisKpis.data.map((kpi: any) => ({
						...kpi
					}));
					setTennisKpiData(tennisKpiData);
				}
			}
			setKpiLoading(false);
		} catch (error) {
			console.log(error);
			setKpiLoading(false);
		}
	}, []);

	const getCountDetails = useCallback(async () => {
		const params = {
			sports: sport
		};
		try {
			const res = await HttpService.get(`${API_CONFIG.totalCounts}`, params);
			setCountDetails(res.data);
		} catch (error) {
			console.log(error);
		}
	}, [sport]);

	useEffect(() => {
		havePermission && getCountDetails();
	}, [havePermission, fetchCountryList, fetchKPIList, getCountDetails]);

	useEffect(() => {
		fetchCountryList();
	}, [fetchCountryList]);

	useEffect(() => {
		fetchKPIList(userData.sports);
	}, [userData.sports]);

	const contextData = useMemo(
		() => ({
			countryLoading,
			kpiLoading,
			countryOptions,
			kpiData,
			tennisKpiData,
			getCountDetails,
			countDetails,

			// filters
			athleteFilters,
			setAthleteFilters,
			scoutingFilters,
			setScoutingFilters,
			recruitingFilters,
			setRecruitingFilters,
			allAthleteFilters,
			setAllAthleteFilters,
			scoutingPlayerFilters,
			setScoutingPlayerFilters,
			recruitingPlayerFilters,
			setRecruitingPlayerFilters,
			contractFilters,
			setContractFilters,

			//Grid
			viewType,
			setViewType
		}),
		[
			countryLoading,
			kpiLoading,
			tennisKpiData,
			countryOptions,
			countDetails,
			getCountDetails,

			// filters
			athleteFilters,
			setAthleteFilters,
			scoutingFilters,
			setScoutingFilters,
			recruitingFilters,
			setRecruitingFilters,
			allAthleteFilters,
			setAllAthleteFilters,
			scoutingPlayerFilters,
			setScoutingPlayerFilters,
			recruitingPlayerFilters,
			setRecruitingPlayerFilters,
			contractFilters,
			setContractFilters,
			viewType,
			setViewType
		]
	);

	return <AthleteContext.Provider value={contextData}>{children}</AthleteContext.Provider>;
};

interface IContextType {
	countryLoading: boolean;
	kpiLoading: boolean;
	countryOptions: ICountryOptions[];
	kpiData: IKpiDataNew[];
	tennisKpiData: IKpiDataNew[];
	getCountDetails: () => void;
	countDetails: ICountDetails;

	// filters
	athleteFilters: IAthleteFilters;
	setAthleteFilters: React.Dispatch<React.SetStateAction<IAthleteFilters>>;
	scoutingFilters: IScoutingFilters;
	setScoutingFilters: React.Dispatch<React.SetStateAction<IScoutingFilters>>;
	recruitingFilters: IRecruitingFilters;
	setRecruitingFilters: React.Dispatch<React.SetStateAction<IRecruitingFilters>>;
	allAthleteFilters: IAllAthleteFilters;
	setAllAthleteFilters: React.Dispatch<React.SetStateAction<IAllAthleteFilters>>;
	scoutingPlayerFilters: IScoutingPlayerFilters;
	setScoutingPlayerFilters: React.Dispatch<React.SetStateAction<IScoutingPlayerFilters>>;
	recruitingPlayerFilters: IRecruitingPlayerFilters;
	setRecruitingPlayerFilters: React.Dispatch<React.SetStateAction<IRecruitingPlayerFilters>>;
	contractFilters: IContractFilters;
	setContractFilters: React.Dispatch<React.SetStateAction<IContractFilters>>;
	viewType: string;
	setViewType: Dispatch<SetStateAction<string>>;
}

export interface ICountryOptions {
	label: string;
	value: string;
	iso3: string;
	name: string;
	continent: string;
	emoji: string;
}

const AthleteContext = createContext({} as IContextType);
export default AthleteStore;
